<template>
  <custom-modal
    :name="name"
    :classes="['change-password-modal']"
    @before-close="reset"
  >
    <template v-slot:header>
      <p class="change-password-modal__title">Change password</p>
    </template>
    <Form v-slot="{ invalid }">
      <FormItem
        v-if="hasPasswordProvider"
        rules="required"
        class="mb-24"
        v-slot="{ isError }"
      >
        <Input
          v-model="form.oldPassword"
          is-password
          postfix="password"
          placeholder="Old password"
          :is-error="isError"
        />
      </FormItem>
      <FormItem
        rules="required|min:6"
        name="new password"
        class="mb-24"
        v-slot="{ isError }"
      >
        <Input
          v-model="form.newPassword"
          is-password
          postfix="password"
          placeholder="New password"
          :is-error="isError"
        />
      </FormItem>
      <FormItem
        rules="required|confirmed:new password"
        class="mb-64"
        v-slot="{ isError }"
      >
        <Input
          v-model="form.newPasswordConfirm"
          is-password
          postfix="password"
          placeholder="Confirm new password"
          :is-error="isError"
        />
      </FormItem>
      <Button
        is-block
        :is-disabled="invalid"
        :is-loading="isLoading"
        class="mb-16"
        @click="$emit('submit', form)"
      >
        Save
      </Button>
      <Button
        is-block
        is-outlined
        :is-disabled="isLoading"
        variant="secondary"
        @click="$modal.hide(name)"
      >
        Cancel
      </Button>
    </Form>
  </custom-modal>
</template>

<script>
import { auth } from "@/plugins/firebase";

export default {
  name: "ChangePasswordModal",
  props: {
    name: {
      type: String,
      default: "changePasswordModal",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
      },
    };
  },
  computed: {
    hasPasswordProvider() {
      return auth.currentUser.providerData.some(
        (provider) => provider.providerId === "password"
      );
    },
  },
  methods: {
    reset() {
      this.form = {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
      };
    },
  },
};
</script>

<style lang="scss">
.change-password-modal {
  padding: 40px 16px 32px;

  @media (min-width: $media-laptop) {
    padding: 40px;
  }
}
</style>

<style lang="scss" scoped>
.change-password-modal {
  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $secondary-500;
    margin-bottom: 40px;
  }
}
</style>
