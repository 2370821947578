<template>
  <div class="profile-page">
    <BackTitle confirm-click @click="goBack"> Profile settings </BackTitle>
    <Form class="profile-page__form" v-slot="{ invalid, changed }">
      <div class="grid-2 mb-32">
        <FormItem rules="required">
          <Input
            label="Your name"
            v-model="form.displayName"
            placeholder="Name"
          />
        </FormItem>
        <FormItem rules="required|email">
          <Input label="Email" v-model="form.email" placeholder="Email" />
        </FormItem>
        <FormItem>
          <Input
            label="Phone"
            v-model="form.phoneNumber"
            placeholder="Phone"
            :isDisabled="true"
          />
        </FormItem>
        <FormItem>
          <ImageUploader
            v-model="form.photoURL"
            label="Photo"
            scope="profiles"
            :max-size="500"
            is-small
          />
        </FormItem>
      </div>
      <Button
        class="mb-24"
        variant="light"
        :is-block="['xs', 'sm'].includes($mq)"
        :isDisabled="isLoading"
        @click="$modal.show(changePasswordModalName)"
      >
        Change password
      </Button>
      <div class="profile-page__save-wrapper">
        <Button
          :is-block="['xs', 'sm'].includes($mq)"
          :isDisabled="invalid || !changed"
          :isLoading="isLoading"
          @click="save"
        >
          Save
        </Button>
      </div>
    </Form>
    <ChangePasswordModal
      :name="changePasswordModalName"
      :is-loading="isChangingPassword"
      @submit="handleChangePassword"
    />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { auth } from "@/plugins/firebase";
import { changePassword, updateUser } from "@/helpers/firebase-helpers";
import ChangePasswordModal from "@/components/auth/ChangePasswordModal";
import ImageUploader from "@/components/common/ImageUploader";

export default {
  name: "ProfilePage",
  components: { ImageUploader, ChangePasswordModal, BackTitle },
  data() {
    const user = auth.currentUser;
    const { email, displayName, phoneNumber, photoURL } = user;
    return {
      form: {
        displayName,
        email,
        phoneNumber,
        photoURL,
      },
      isLoading: false,
      changePasswordModalName: "changePasswordModal",
      isChangingPassword: false,
    };
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await updateUser(this.form);
        this.goBack();
      } finally {
        this.isLoading = false;
      }
    },
    goBack() {
      this.$router.push({
        name: "Settings",
      });
    },
    async handleChangePassword(data) {
      try {
        this.isChangingPassword = true;
        const changed = await changePassword(data);
        if (changed) {
          this.$modal.hide(this.changePasswordModalName);
        }
      } finally {
        this.isChangingPassword = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }

  &__save-wrapper {
    border-top: 1px solid $secondary-300;
    padding-top: 24px;
    margin-top: auto;
    width: 100%;

    @media (min-width: $media-laptop) {
      border: none;
    }
  }
}
</style>
