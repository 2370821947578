<template>
  <div class="image-uploader">
    <p class="image-uploader__label">{{ label }}</p>
    <div
      class="image-uploader__box"
      :class="{ 'm-small': isSmall, 'm-disabled': isDisabled }"
    >
      <template v-if="!innerValue">
        <Icon name="plus" color="primary" />
        <input
          v-if="!isDisabled"
          type="file"
          accept="image/*"
          tabindex="-1"
          class="image-uploader__input m-overlay"
          @change="handleUpload"
        />
      </template>
      <template v-else>
        <img class="image-uploader__image" :src="innerValue" alt="" />
        <div v-if="!isDisabled" class="image-uploader__clear">
          <Icon
            name="close"
            color="danger"
            :size="16"
            is-clickable
            @click="clear"
          />
        </div>
      </template>
      <div v-if="isSubmitting" class="image-uploader__loader m-overlay">
        <Loader size="l" color="white" />
      </div>
    </div>
  </div>
</template>

<script>
import MediaService from "@/api/services/MediaService";
import alert from "@/plugins/alert";

export default {
  name: "ImageUploader",
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Image",
    },
    scope: {
      type: String,
      required: true,
    },
    maxSize: {
      type: Number,
      default: 100, // px
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    getDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const image = new Image();
          image.onload = () => {
            const canvas = document.createElement("canvas");
            const maxSize = this.maxSize;
            let width = image.width;
            let height = image.height;
            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else {
              if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            resolve(canvas.toDataURL(file.type));
          };
          image.src = reader.result;
        };
        reader.onerror = (error) => reject(error);
      });
    },
    async handleUpload(event) {
      const file = event.target.files[0];
      event.target.value = null;
      if (file && file.type.match(/image.*/) && this.scope) {
        try {
          this.isSubmitting = true;
          const dataUrl = await this.getDataURL(file);
          const { url } = await MediaService.uploadImage({
            dataUrl,
            scope: this.scope,
          });
          this.innerValue = url;
        } catch {
          await alert.open({
            message: "An error occurred while uploading the image",
            variant: "danger",
          });
        } finally {
          this.isSubmitting = false;
        }
      } else {
        await alert.open({
          message: "An invalid file was selected",
          variant: "warning",
        });
      }
    },
    clear() {
      this.innerValue = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.image-uploader {
  &__label {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: $secondary-500;
  }

  &__box {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $secondary-400;
    transition: 0.3s;
    overflow: hidden;

    &:hover {
      background-color: $secondary-100;
    }

    &.m-small {
      width: 64px;
      height: 64px;
    }

    &.m-disabled {
      background-color: $secondary-100;
      cursor: not-allowed;
    }
  }

  &__input {
    opacity: 0;
    cursor: pointer;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__clear {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 1;
    padding: 2px;
    border-radius: 100%;
    background-color: $white;
  }

  &__loader {
    background-color: $secondary-400;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .m-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
}
</style>
