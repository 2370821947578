import ServiceBase from "@/api/core";

const baseURL = process.env.VUE_APP_API_BASE_URL;

export default class MediaService extends ServiceBase {
  static uploadImage(data) {
    const url = "/public/media/images/upload";
    return this.callApi({
      method: "POST",
      baseURL,
      url,
      data,
    });
  }
}
